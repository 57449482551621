<template>
  <v-content
    :style="{
      background: isOrderButtonsOn ? '#cccccc' : '',
      padding: 0,
    }"
    ref="orientationDiv"
  >
    <draggable
      v-if="isOrderButtonsOn"
      v-model="buttonListEnabled"
      group="buttons"
      @start="drag = true"
      @end="drag = false"
      :sort="isOrderButtonsOn"
      class="row"
    >
      <v-col
        v-for="(btn, i) in buttonListEnabled"
        :key="btn.id"
        style="text-align: center"
        :cols="isOrientationPortrait ? 4 : 3"
      >
        <v-img
          :key="i"
          :src="
            btn.id == 'MSA'
              ? require('@/assets/msa.svg')
              : btn.id == 'SAP'
              ? require('@/assets/sap.svg')
              : btn.id == 'USR'
              ? require('@/assets/usr.svg')
              : btn.id == 'SGQ'
              ? require('@/assets/SGQ.png')
              : btn.id == 'ST'
              ? require('@/assets/Schede tecniche.png')
              : btn.id == 'MNT'
              ? require('@/assets/MANUTENZIONE.png')
              : btn.id == 'HSE'
              ? require('@/assets/HSE.png')
              : btn.id == 'BO'
              ? require('@/assets/BUSINESS OBJECT.png')
              : btn.id == 'STAIN'
              ? require('@/assets/DASHBOARD STAIN.png')
              : btn.id == 'WEB'
              ? require('@/assets/STUDIOWEB.png')
              : btn.id == 'UTILITY'
              ? require('@/assets/UTILITY.png')
              : btn.id == 'EQPMNG'
              ? require('@/assets/GESTIONE ATTREZZATURE.png')
              : ''
          "
        ></v-img>
        <span style="font-weight: bold">{{ $tc(btn.t) }}</span>
      </v-col>
    </draggable>
    <v-row v-if="!isOrderButtonsOn">
      <v-col
        v-for="(btn, i) in buttonListEnabled"
        :key="btn.id"
        @click="goToPage(btn.r, btn.id)"
        style="text-align: center"
        :cols="isOrientationPortrait ? 4 : 3"
      >
        <v-img
          :key="i"
          :src="
            btn.id == 'MSA'
              ? require('@/assets/msa.svg')
              : btn.id == 'SAP'
              ? require('@/assets/sap.svg')
              : btn.id == 'USR'
              ? require('@/assets/usr.svg')
              : btn.id == 'SGQ'
              ? require('@/assets/SGQ.png')
              : btn.id == 'ST'
              ? require('@/assets/Schede tecniche.png')
              : btn.id == 'MNT'
              ? require('@/assets/MANUTENZIONE.png')
              : btn.id == 'HSE'
              ? require('@/assets/HSE.png')
              : btn.id == 'BO'
              ? require('@/assets/BUSINESS OBJECT.png')
              : btn.id == 'STAIN'
              ? require('@/assets/DASHBOARD STAIN.png')
              : btn.id == 'WEB'
              ? require('@/assets/STUDIOWEB.png')
              : btn.id == 'UTILITY'
              ? require('@/assets/UTILITY.png')
              : btn.id == 'EQPMNG'
              ? require('@/assets/GESTIONE ATTREZZATURE.png')
              : ''
          "
        ></v-img>
        <span style="font-weight: bold">{{ $tc(btn.t) }}</span>
      </v-col>
    </v-row>
  </v-content>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { buttonSortService } from "@/services/api/buttonSort.service";
import AuthModule from "@/store/modules/auth.module";
import OverlayModule from "@/store/modules/overlay.module";
import SnackbarModule from "@/store/modules/snackbar.module";
import store from "@/store";
import { HomeButton } from "@/models/entities/components/components.interface";
import { InsertItem } from "@/models/entities/buttonSort/buttonSort.interface";
import { rapidLinkService } from "@/services/api/rapidLink.service";
import { RapidLinkEnum } from "@/models/enumerations/rapidLinkEnum";

const authModule = getModule(AuthModule, store);
const overlayModule = getModule(OverlayModule, store);
const snackbarModule = getModule(SnackbarModule, store);

@Component
export default class HomePage extends Vue {
  @Prop({ required: false, type: Boolean, default: false })
  isOrderButtonsOn: boolean | undefined;

  buttonST = {
    id: "ST",
    r: "/datasheetList",
    t: "home.buttonST",
    c: "mod--color_btn_sap",
    isExt: true,
    enabled: true,
    sort: 0,
  };
  buttonUtility = {
    id: "UTILITY",
    r: "/utility",
    t: "home.buttonUTILITY",
    c: "mod--color_btn_sap",
    isExt: true,
    enabled: true,
    sort: 0,
  };
  buttonMNT = {
    id: "MNT",
    r: "/maintenance",
    t: "home.buttonMNT",
    c: "mod--color_btn_sap",
    isExt: true,
    enabled: true,
    sort: 0,
  };
  buttonEQPMNG = {
    id: "EQPMNG",
    r: "/equipmentmanagement",
    t: "home.buttonEQPMNG",
    c: "mod--color_btn_sap",
    isExt: true,
    enabled: true,
    sort: 0,
  };

  buttonList: HomeButton[] = [
    {
      id: "SAP",
      r: "",
      t: "home.buttonSAP",
      c: "mod--color_btn_sap",
      isExt: true,
      enabled:
        authModule.sectionsList.filter((x) => {
          return x.authId === "SAP";
        }).length > 0,
      sort: 0,
    },
    {
      id: "MSA",
      r: "/msa",
      t: "home.buttonMSA",
      c: "mod--color_btn_msa",
      isExt: false,
      enabled:
        authModule.sectionsList.filter((x) => {
          return x.authId === "MSA";
        }).length > 0,
      sort: 0,
    },
    {
      id: "USR",
      r: "/userManagement",
      t: "home.buttonUSR",
      c: "mod--color_btn_management",
      isExt: false,
      enabled:
        authModule.sectionsList.filter((x) => {
          return x.authId === "USR";
        }).length > 0,
      sort: 0,
    },
    {
      id: "SGQ",
      r: "/theseus",
      t: "home.buttonSGQ",
      c: "mod--color_btn_sap",
      isExt: true,
      enabled:
        authModule.sectionsList.filter((x) => {
          return x.authId === "SGQ";
        }).length > 0,
      sort: 0,
    },
    {
      id: "HSE",
      r: "",
      t: "home.buttonHSE",
      c: "mod--color_btn_sap",
      isExt: true,
      enabled:
        authModule.sectionsList.filter((x) => {
          return x.authId === "HSE";
        }).length > 0,
      sort: 0,
    },
    {
      id: "BO",
      r: "",
      t: "home.buttonBO",
      c: "mod--color_btn_sap",
      isExt: true,
      enabled:
        authModule.sectionsList.filter((x) => {
          return x.authId === "BO";
        }).length > 0,
      sort: 0,
    },
    {
      id: "STAIN",
      r: "",
      t: "home.buttonSTAIN",
      c: "mod--color_btn_sap",
      isExt: true,
      enabled:
        authModule.sectionsList.filter((x) => {
          return x.authId === "STAIN";
        }).length > 0,
      sort: 0,
    },
    {
      id: "WEB",
      r: "",
      t: "home.buttonWEB",
      c: "mod--color_btn_sap",
      isExt: true,
      enabled:
        authModule.sectionsList.filter((x) => {
          return x.authId === "WEB";
        }).length > 0,
      sort: 0,
    },
  ];
  buttonListEnabled: HomeButton[] = [];
  isOrientationPortrait = true;
  file = null;

  isPresentDatasheetAuth(authId: string): boolean {
    return ["SAT", "SDS", "SMP", "STS", "STT"].includes(authId);
  }

  isPresentMaintenanceAuth(authId: string): boolean {
    return ["SMALLMNT", "MANMNT"].includes(authId);
  }

  isPresentUtilityAuth(authId: string): boolean {
    return ["RUBRIC", "SECCHART"].includes(authId);
  }

  isPresentEquipmentManagementAuth(authId: string): boolean {
    return ["ACTIVEPARTS"].includes(authId);
  }

  mounted(): void {
    this.checkOrientation();
    this.reloadButtonOrder();
  }

  isRapidLink(authId: string): boolean {
    return ["HSE", "STAIN", "BO", "SAP", "WEB"].includes(authId);
  }

  created() {
    window.addEventListener("resize", this.checkOrientation);
  }

  destroyed() {
    window.removeEventListener("resize", this.checkOrientation);
  }

  async goToPage(path: string, id: string) {
    if (id === "SGQ") {
      window.open(path, "_blank");
    }
    if (this.isRapidLink(id)) {
      const response = await rapidLinkService.getLinkByAuth(RapidLinkEnum[id]);
      window.open(response.data, "_blank");
    } else {
      await this.$router.replace({ path: path });
    }
  }

  checkOrientation(): void {
    const orientation = window.screen.orientation.type;
    this.isOrientationPortrait = orientation === "portrait-primary";
  }

  async reloadButtonOrder(): Promise<void> {
    overlayModule.showOverlay();
    let btnTrueInsert = this.buttonList.filter((x) => {
      return x.enabled === true;
    });
    try {
      const response = await buttonSortService.getOrderedAuths();

      let btnList: HomeButton[] = [];
      for (const btn of response.data) {
        let btnInsert = btnTrueInsert.filter((x) => {
          return x.id === btn.authId;
        });
        if (btnInsert && btnInsert.length > 0) {
          btnInsert[0].sort = btn.sort;
          if (
            btn.authId !== "ST" &&
            btn.authId !== "MNT" &&
            btn.authId !== "UTILITY" &&
            btn.authId !== "EQPMNG"
          ) {
            btnList.push(btnInsert[0]);
          }
        }
        if (this.isPresentDatasheetAuth(btn.authId)) {
          const buttonSTSort = response.data.find(
            (el) => el.authId === "ST"
          )?.sort;
          if (!btnList.find((el) => el === this.buttonST)) {
            this.buttonST.sort = buttonSTSort;
            btnList.push(this.buttonST);
          }
        }
        if (this.isPresentMaintenanceAuth(btn.authId)) {
          const buttonMNTSort = response.data.find(
            (el) => el.authId === "MNT"
          )?.sort;
          if (!btnList.find((el) => el === this.buttonMNT)) {
            this.buttonMNT.sort = buttonMNTSort;
            btnList.push(this.buttonMNT);
          }
        }
        if (this.isPresentUtilityAuth(btn.authId)) {
          const buttonSTSort = response.data.find(
            (el) => el.authId === "UTILITY"
          )?.sort;
          if (!btnList.find((el) => el === this.buttonUtility)) {
            this.buttonUtility.sort = buttonSTSort;
            btnList.push(this.buttonUtility);
          }
        }
        if (this.isPresentEquipmentManagementAuth(btn.authId)) {
          const buttonSTSort = response.data.find(
            (el) => el.authId === "EQPMNG"
          )?.sort;
          if (!btnList.find((el) => el === this.buttonEQPMNG)) {
            this.buttonEQPMNG.sort = buttonSTSort;
            btnList.push(this.buttonEQPMNG);
          }
        }
      }
      this.reOrderButtonsHome(btnList);
    } catch (e) {
      snackbarModule.appearSnackbar({
        message: this.$tc("home.snackOrderNotLoaded"),
        type: "info",
      });
      this.buttonListEnabled = this.buttonList;
    }

    overlayModule.hideOverlay();
  }

  @Watch("isOrderButtonsOn")
  submitEdit(): void {
    if (!this.isOrderButtonsOn) {
      overlayModule.showOverlay();
      let submitArr: InsertItem[] = [];
      this.buttonListEnabled.forEach((btn, i) => {
        submitArr.push({
          userId: authModule.userID,
          authId: btn.id,
          sort: i + 1,
        });
      });
      buttonSortService
        .insertSorts(submitArr)
        .then(() => {
          snackbarModule.appearSnackbar({
            message: this.$tc("home.snackOrderChangeSuccess"),
            type: "success",
          });
        })
        .catch(() => {
          snackbarModule.appearSnackbar({
            message: this.$tc("home.snackOrderChangeFailure"),
            type: "warning",
          });
        })
        .finally(() => {
          overlayModule.hideOverlay();
          this.reloadButtonOrder();
        });
    }
  }

  reOrderButtonsHome(btnList: HomeButton[]): void {
    btnList.sort((a, b) => a.sort - b.sort);
    this.buttonListEnabled = btnList;
  }
}
</script>
<style scoped></style>
